<template>
  <router-link
    class="block min-w-56 leading-15 text-center text-xs uppercase font-semibold border border-blue transition-all"
    :class="styleClasses"
    :to="link">
    {{ content }}
  </router-link>
</template>

<script>
export default {
  name: "SmallButton",
  props: ['content', 'link', 'styleClasses']
}
</script>