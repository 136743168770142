<template>
  <div class="bg-white">
    <div class="container mx-auto pt-12">
      <h1 class="text-5xl">Каталог продукции</h1>

      <div class="grid grid-cols-3 py-12">
        <!-- Filters -->
        <div class="flex flex-col gap-5">
          <Listbox v-model="selectedPerson">
            <div class="flex gap-5 relative">
              <ListboxLabel class="flex place-items-center"><span class="">Сортировка:</span></ListboxLabel>
              <ListboxButton
                class="relative w-64 border border border-dark-gray cursor-default bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
              >
                <span class="block truncate">{{ selectedPerson.name }}</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </span>
              </ListboxButton>
              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-slot="{ active, selected }"
                    v-for="person in people"
                    :key="person.name"
                    :value="person"
                    as="template"
                  >
                    <li
                      :class="[
                  active ? 'bg-light-blue text-blue' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
                    >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >{{ person.name }}</span
                >
                      <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue"
                      >
                  <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <div class="flex place-items-center gap-5">
            <Switch
              v-model="horecaItems"
              :class="horecaItems ? 'bg-blue' : 'bg-dark-gray'"
              class="relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <span class="sr-only">Horeca (гостиницы, кафе, рестораны)</span>
              <span
                aria-hidden="true"
                :class="horecaItems ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <label>Horeca (гостиницы, кафе, рестораны)</label>
          </div>
          <div class="flex place-items-center gap-5">
            <Switch
              v-model="retailItems"
              :class="retailItems ? 'bg-blue' : 'bg-dark-gray'"
              class="relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <span class="sr-only">Retail (розничная торговля)</span>
              <span
                aria-hidden="true"
                :class="retailItems ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <label>Retail (розничная торговля)</label>
          </div>
          <div class="flex place-items-center gap-5">
            <Switch
              v-model="stockItems"
              :class="stockItems ? 'bg-blue' : 'bg-dark-gray'"
              class="relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <span class="sr-only">Склад</span>
              <span
                aria-hidden="true"
                :class="stockItems ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <label>Склад</label>
          </div>
        </div>
        <div class="flex flex-col col-span-2 divide-y divide-dark-gray border-y border-dark-gray">
          <div class="grid grid-cols-3 gap-5 py-10">
            <img src="@/assets/products/img.png" alt="">
            <div class="flex flex-col gap-5 col-span-2">
              <h2 class="text-3xl">Элвес МК</h2>

              <div class="flex gap-2 text-right text-xs text-blue uppercase">
                <p class="border p-2 border-blue">Фискальный регистратор</p>
                <p class="border p-2 border-blue">Онлайн-касса</p>
              </div>

              <p>Стационарная онлайн-касса. Отличается эргономичным дизайном, компактными габаритами и привлекательной
                ценой, сохраняя непревзойденное качество благодаря надежному японскому принтеру чеков.</p>
              <ul class="list-inside list-disc">
                <li>Бюджетное решение</li>
                <li>Экономит место</li>
                <li>Подключение по microUSB</li>
                <li>Передача данных в налоговую инспекцию</li>
              </ul>
              <p class="text-2xl text-blue font-medium">20 999₽</p>
              <button
                class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
                Купить
              </button>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-5 py-10">
            <img src="@/assets/products/img.png" alt="">
            <div class="flex flex-col gap-5 col-span-2">
              <h2 class="text-3xl">Элвес МК</h2>

              <div class="flex gap-2 text-right text-xs text-blue uppercase">
                <p class="border p-2 border-blue">Фискальный регистратор</p>
                <p class="border p-2 border-blue">Онлайн-касса</p>
              </div>

              <p>Стационарная онлайн-касса. Отличается эргономичным дизайном, компактными габаритами и привлекательной
                ценой, сохраняя непревзойденное качество благодаря надежному японскому принтеру чеков.</p>
              <ul class="list-inside list-disc">
                <li>Бюджетное решение</li>
                <li>Экономит место</li>
                <li>Подключение по microUSB</li>
                <li>Передача данных в налоговую инспекцию</li>
              </ul>
              <p class="text-2xl text-blue font-medium">20 999₽</p>
              <button
                class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
                Купить
              </button>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-5 py-10">
            <img src="@/assets/products/img.png" alt="">
            <div class="flex flex-col gap-5 col-span-2">
              <h2 class="text-3xl">Элвес МК</h2>

              <div class="flex gap-2 text-right text-xs text-blue uppercase">
                <p class="border p-2 border-blue">Фискальный регистратор</p>
                <p class="border p-2 border-blue">Онлайн-касса</p>
              </div>

              <p>Стационарная онлайн-касса. Отличается эргономичным дизайном, компактными габаритами и привлекательной
                ценой, сохраняя непревзойденное качество благодаря надежному японскому принтеру чеков.</p>
              <ul class="list-inside list-disc">
                <li>Бюджетное решение</li>
                <li>Экономит место</li>
                <li>Подключение по microUSB</li>
                <li>Передача данных в налоговую инспекцию</li>
              </ul>
              <p class="text-2xl text-blue font-medium">20 999₽</p>
              <button
                class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
                Купить
              </button>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-5 py-10">
            <img src="@/assets/products/img.png" alt="">
            <div class="flex flex-col gap-5 col-span-2">
              <h2 class="text-3xl">Элвес МК</h2>

              <div class="flex gap-2 text-right text-xs text-blue uppercase">
                <p class="border p-2 border-blue">Фискальный регистратор</p>
                <p class="border p-2 border-blue">Онлайн-касса</p>
              </div>

              <p>Стационарная онлайн-касса. Отличается эргономичным дизайном, компактными габаритами и привлекательной
                ценой, сохраняя непревзойденное качество благодаря надежному японскому принтеру чеков.</p>
              <ul class="list-inside list-disc">
                <li>Бюджетное решение</li>
                <li>Экономит место</li>
                <li>Подключение по microUSB</li>
                <li>Передача данных в налоговую инспекцию</li>
              </ul>
              <p class="text-2xl text-blue font-medium">20 999₽</p>
              <button
                class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
                Купить
              </button>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-5 py-10">
            <img src="@/assets/products/img.png" alt="">
            <div class="flex flex-col gap-5 col-span-2">
              <h2 class="text-3xl">Элвес МК</h2>

              <div class="flex gap-2 text-right text-xs text-blue uppercase">
                <p class="border p-2 border-blue">Фискальный регистратор</p>
                <p class="border p-2 border-blue">Онлайн-касса</p>
              </div>

              <p>Стационарная онлайн-касса. Отличается эргономичным дизайном, компактными габаритами и привлекательной
                ценой, сохраняя непревзойденное качество благодаря надежному японскому принтеру чеков.</p>
              <ul class="list-inside list-disc">
                <li>Бюджетное решение</li>
                <li>Экономит место</li>
                <li>Подключение по microUSB</li>
                <li>Передача данных в налоговую инспекцию</li>
              </ul>
              <p class="text-2xl text-blue font-medium">20 999₽</p>
              <button
                class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
                Купить
              </button>
            </div>
          </div>
        </div>
        <vue-awesome-paginate
          :total-items="43"
          :items-per-page="5"
          :max-pages-shown="5"
          :current-page="1"
          :on-click="onClickHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Switch
} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from '@heroicons/vue/solid'

const sortOptions = [
  {name: 'по популярности'},
  {name: 'по возрастанию стоимости'},
  {name: 'по убыванию стоимости'},
]
const selectedSortOption = ref(sortOptions[0])

const retailItems = ref(true)
const horecaItems = ref(true)
const stockItems = ref(true)

export default {
  name: "ProductsView",
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
    Switch
  },
  data: () => ({
    selectedPerson: selectedSortOption,
    people: sortOptions,
    retailItems,
    horecaItems,
    stockItems
  })
}
</script>

<style scoped>

</style>