<template>
  <div class="flex w-full h-screen place-content-center">
    <div class="flex flex-col mx-5 xl:mx-auto w-100 xl:w-1/2 gap-5 xl:gap-10 m-auto">
      <img class="h-[118px]" src="@/assets/logo.svg" alt="Логотип НТЦ Базис">
      <h1 class="text-blue text-xl xl:text-3xl 2xl:text-4xl leading-none font-medium uppercase">Сайт закрыт на техническое
        обслуживание</h1>
      <div class="flex flex-col gap-1">
        <h1 class="text-black xl:text-xl 2xl:text-2xl leading-none font-medium uppercase mb-2">Контакты НТЦ "Базис":</h1>
        <p>Сервисный отдел: <a class="hover:text-blue" href="tel:+79496141314">+7 (949) 614-13-14</a></p>
        <p>Торговый отдел: <a class="hover:text-blue" href="tel:+79495013335">+7 (949) 501-33-35</a></p>
        <p>Абонентский отдел: <a class="hover:text-blue" href="tel:+79495195149">+7 (949) 519-51-49</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceMode"
}
</script>

<style scoped>

</style>