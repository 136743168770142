<template>
  <router-link
    class="bg-white after:block after:pb-full flex justify-center items-center hover:bg-blue hover:text-white transition-all"
    :to="link">
    <div class="absolute">
      <div class="flex flex-col gap-16">
        <img class="w-72" :src="require(`@/assets/products/${image}`)" alt="{{ content }}">
        <p class="uppercase text-center">{{ content }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProductItemButton",
  props: ['content', 'image', 'link']
}
</script>

<style scoped>

</style>