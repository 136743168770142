<template>
  <router-link class="group hover:scale-105 transition-all" :to="link">
    <div class="w-56 h-56 border border-dark-gray grid content-center group-hover:border-blue">
      <img class="w-16 h-16 mx-auto" :src="require(`@/assets/icons/${icon}`)" alt="{{ content }}">
    </div>
    <p class="text-center mt-5 group-hover:text-blue uppercase">{{ content }}</p>
  </router-link>
</template>

<script>
export default {
  name: "CategoryButton",
  props: ['link', 'content', 'icon']
}
</script>