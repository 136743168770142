<template>
  <div class="bg-white">

    <div class="container mx-auto pt-12">
      <h1 class="text-5xl">Контакты</h1>

      <div class="flex flex-col gap-5 mt-12">
        <h2 class="text-2xl font-medium">Телефоны</h2>
        <p><span class="font-medium">Отдел продаж: </span><a class="text-blue underline" href="tel:+38071">+7 (949) 123
          12 12</a></p>
        <p><span class="font-medium">Техническая поддержка: </span><a class="text-blue underline" href="tel:+38071">+7
          (949) 123 12 12</a></p>
        <p><span class="font-medium">Бухгалтерия: </span><a class="text-blue underline" href="tel:+38071">+7 (949) 123
          12 12</a></p>
      </div>
      <div class="flex flex-col gap-5 mt-12">
        <h2 class="text-2xl font-medium">Обратная связь</h2>
        <form class="grid grid-cols-2 gap-5" action="/">
          <div>
            <label for="name" class="mb-2 text-sm font-regular text-dark-gray sr-only">Ваше имя</label>
            <input
              id="name"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Ваше имя*" required>
          </div>
          <div>
            <label for="company" class="mb-2 text-sm font-regular text-dark-gray sr-only">Компания</label>
            <input
              id="company"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Компания">
          </div>
          <div>
            <label for="name" class="mb-2 text-sm font-regular text-dark-gray sr-only">Номер телефона</label>
            <input
              id="name"
              class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
              type="text" placeholder="Номер телефона*" required>
          </div>
          <textarea
            class="h-20 col-span-2 block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
            type="text" name="question" id="question" placeholder="Ваш вопрос" required></textarea>
          <button class="uppercase text-white bg-blue py-4 line px-9 text-sm content-center w-80">Отправить</button>
        </form>
      </div>
      <div class="flex flex-col gap-5 mt-12">
        <h2 class="text-2xl font-medium">Адрес офиса</h2>
        <p><span class="font-medium">Адрес: </span>83001, ДНР, г. Донецк, ул. Постышева, д. 60, этаж 3</p>
        <p><span class="font-medium">Режим работы: </span>10:00 - 17:00 (кроме выходных и праздничных дней)</p>
      </div>
    </div>
    <iframe
      class="mt-5 mb-12"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A3ca9147f2313aad6b92bc9dee0fe837b6a7d01dd67f8dcc31327bf04db2f616d&amp;source=constructor"
      width="100%" height="450" frameborder="0"></iframe>
  </div>
</template>

<script>

export default {
  name: "ContactsView",
}
</script>

<style scoped>

</style>