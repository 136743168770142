<template>
  <div class="bg-white">
    <div class="container mx-auto pt-12 pb-24">
      <h1 class="text-5xl">История компании</h1>
      <p class="text-base font-normal mt-12">Augue varius sed non amet amet dignissim. Porta metus
        pellentesque purus in enim facilisis
        imperdiet id. Morbi cursus ut sit neque condimentum. Viverra mauris adipiscing platea tortor,
        nunc mi. Diam at fringilla etiam turpis pharetra magna.
        Tortor duis nunc sed arcu nunc, auctor venenatis at. Id eget venenatis enim id pharetra mauris
        accumsan auctor sed. Quis egestas eget sem lectus et in nunc, enim.

        Diam velit dolor duis feugiat. Vestibulum urna ipsum, sit leo vel. Orci mattis leo gravida in
        purus ultrices. Morbi arcu nulla enim, sed bibendum. Mus id habitant enim ac. Elit malesuada
        venenatis vel vel faucibus adipiscing sodales posuere quis. Egestas rhoncus viverra tincidunt
        gravida parturient sollicitudin. Congue sed vel urna, et aliquam feugiat a, id. Sed integer
        condimentum eu purus, vitae. Duis viverra aenean lectus et. Hac volutpat non dictum viverra
        vestibulum faucibus.</p>
      <div class="mt-20 flex flex-col divide-y divide-dark-gray border-y border-dark-gray">
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2016</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2017</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2018</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2019</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2020</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2021</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
        <div class="grid grid-cols-2 py-10 px-3 items-center gap-5">
          <p class="text-2xl font-normal"><span class="text-6xl">2022</span> год</p>
          <p>Proin dolor, libero maecenas pellentesque non tortor. Mattis ultricies montes, diam vehicula
            nisl felis aliquet elementum vitae. Imperdiet placerat semper neque, in tincidunt curabitur.
            Tincidunt tellus pharetra faucibus accumsan.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryView"
}
</script>

<style scoped>

</style>