<template>
  <div class="bg-white">
    <div class="container mx-auto pt-12">
      <h1 class="text-5xl">Наши клиенты</h1>
      <h2 class="my-10">Тысячи расчетно-кассовых аппаратов НТЦ БАЗИС беспрерывно работают на благо наших клиентов!</h2>
      <div class="grid grid-cols-3 gap-5 mt-10 pb-12">
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
        <client-item-button
          content="Супермаркет PARUS"
          image="parus.png"
          link="https://parus.market"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientItemButton from "@/components/buttons/ClientItemButton";

export default {
  name: "ClientsView",
  components: {
    ClientItemButton
  }
}
</script>

<style scoped>

</style>