import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ServiceView from '../views/ServiceView.vue'
import ProductsView from '../views/ProductsView.vue'
import ContactsView from '../views/ContactsView.vue'
import HistoryView from '../views/HistoryView.vue'
import NewsView from '../views/NewsView.vue'
import ClientsView from '../views/ClientsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // component: () => import('../views/AboutView.vue')
    component: AboutView
  },
  {
    path: '/about/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/about/clients',
    name: 'clients',
    component: ClientsView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/service',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
