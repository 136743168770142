<template>
  <div class="bg-light-blue border-b border-b-dark-gray">
    <div class="container mx-auto grid grid-cols-3 divide-x divide-dark-gray">
      <div class="my-12 mr-12 w-100">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="0"
          :pagination=pagination
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <swiper-item title="Элвес МК" subtitle="Портативность по доступной цене" link="/"/>
          </swiper-slide>
          <swiper-slide>
            <swiper-item title="Элвес МК" subtitle="Портативность по доступной цене" link="/"/>
          </swiper-slide>
          <swiper-slide>
            <swiper-item title="Элвес МК" subtitle="Портативность по доступной цене" link="/"/>
          </swiper-slide>
          <swiper-slide>
            <swiper-item title="Элвес МК" subtitle="Портативность по доступной цене" link="/"/>
          </swiper-slide>

          <div class="flex gap-10 mt-10">
            <SwiperPrevButton/>
            <div class="swiper-pagination flex gap-2"></div>
            <SwiperNextButton/>
          </div>
        </swiper>
      </div>
      <div class="col-span-2">
        <div class="flex flex-col divide-y divide-dark-gray">
          <div class="my-12 ml-12">
            <img class="w-full object-cover" src="@/assets/image.png" alt="">
          </div>
          <div class="flex py-12 pl-12 items-center justify-between">
            <p class="text-3xl font-medium uppercase">Полезные разделы</p>
            <div class="flex gap-5">
              <big-blue-button content="Услуги" link="/"/>
              <big-blue-button content="О
                регистрация РРО" link="/"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';

import SwiperItem from "@/components/swiper/SwiperItem";
import 'swiper/css';
import SwiperNextButton from "@/components/swiper/SwiperNextButton";
import SwiperPrevButton from "@/components/swiper/SwiperPrevButton";
import BigBlueButton from "@/components/buttons/BigBlueButton";

export default {
  name: "HomeSwiper",
  components: {
    SwiperPrevButton,
    SwiperNextButton,
    SwiperItem,
    Swiper,
    SwiperSlide,
    BigBlueButton
  },
  setup() {
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSlideChange,
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + ' w-2 h-2 bg-dark-gray rounded-full cursor-pointer"></span>';
        }
      }
    };
  }
}
</script>
<style>
.swiper-pagination-bullet-active {
  background: rgb(0 95 143) !important;
}
</style>