<template>
  <div class="bg-white">
    <div class="container mx-auto py-12">
      <h1 class="text-5xl">Новости и медиа</h1>
      <div class="flex flex-col divide-y divide-dark-gray border-y border-dark-gray mt-14">
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image3.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image4.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image5.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image5.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image5.png" alt="">
        </div>
      </div>
      <vue-awesome-paginate
        :total-items="10"
        :items-per-page="5"
        :max-pages-shown="5"
        :current-page="1"
        :on-click="onClickHandler"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsView"
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
  margin-top: 48px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #005F8F;
  border: 1px solid #005F8F;
  color: white;
}

.active-page:hover {
  background-color: #005F8F;
}
</style>