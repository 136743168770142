<template>
  <div class="flex flex-col items-start">
    <p class="text-sm font-semibold uppercase">{{ subtitle }}</p>
    <h3 class="text-blue text-6xl 2xl:text-7xl leading-none font-medium uppercase mt-3 mb-6">{{ title }}</h3>
    <p class="text-lg leading-tight font-medium">Самый бюджетный портативный кассовый аппарат от НТЦ “Базис”
    </p>
    <ul class="list-disc marker:text-blue list-inside mt-5">
      <li>Легкий и автономный</li>
      <li>Надежный</li>
      <li>Работает при низких температурах</li>
    </ul>
    <small-button content="Узнать подробнее" styleClasses="w-56 text-blue hover:bg-blue hover:text-white mt-7"
                  link="#"/>
  </div>
</template>

<script>
import SmallButton from "@/components/buttons/SmallButton";

export default {
  name: "SwiperItem",
  components: {SmallButton},
  props: ['title', 'subtitle', 'content', 'link']
}
</script>

<style scoped>

</style>