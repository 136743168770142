<template>
  <router-link
    class="block w-56 leading-20 text-center text-xs uppercase font-semibold bg-blue text-white hover:bg-white
    hover:text-blue border border-blue hover:border-blue transition-all"
    :to="link">
    {{ content }}
  </router-link>
</template>

<script>
export default {
  name: "BigBlueButton",
  props: ['link', 'content']
}
</script>