<template>
  <MaintenanceMode/>
</template>
<script>

// import TheHeader from "@/components/TheHeader";
// import TheFooter from "@/components/TheFooter";
import MaintenanceMode from "@/components/MaintenanceMode";

export default {
  // components: {TheHeader, TheFooter, MaintenanceMode}
  components: {MaintenanceMode}
}
</script>